import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Edit = () => import('./views/edit.vue');
const Preview = () => import('./views/preview.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'edit',
        component: Edit,
    },
    {
        path: 'preview',
        component: Preview,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;

