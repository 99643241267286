const partConfiguration: any = {
    // 项目名称: "岐黄数据AI工作站",
    // 项目名称: "中医远程数据智能研究系统",
    项目名称: "",
    name: "saas",// 项目名称，用来项目部分功能的区分。目前saas诊次列表后端返回数据不一样
    nameText: '患者姓名',// 新增患者和编辑患者姓名字段的显示文字
    phoneText: '联系方式',// 新增患者和编辑患者联系方式字段的显示文字
    composeType: "sizhen",// 控制编辑账户页面显示效果，默认不支持绑定四诊设备，sizhen可以绑定四诊设备
    isShowSwitch: true,// 是否支持控制专病库显示与否，默认不显示此控制按钮
    canAddMore: true,// 是否支持关联多个账号
    selectType: 'normal',// 专病库选择列表页，normal(常规的)，demo(测试的,会有一些假的专病)
    isShowShujuchi: true,// 是否显示接入数据池
    isShowYiyuan: false,// 是否显示接入医院业务系统
    shujuchiPage: 'saas',// 对接数据池对应的页面
    yiyuanPage: 'saas',// 对接医院业务系统对应的页面
    首页: {
        name: "首页",// 一级目录名称，所有项目一致，无需修改
        label: "首页",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        homeType: 'saas',
        path: "/main/home/home-show",// 点击跳转的路径，每个项目可自行配置
        activePath: "/main/home/home-show"// 用来根据路径选中当前模块
    },
    数据中心: {
        name: "数据中心",// 一级目录名称，所有项目一致，无需修改
        label: "数据集成",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        child: [
            {
                parent: "数据中心",// 对应的一级名称，所有项目一致，无需修改
                name: "数据看板",// 所有项目统一名称,无需修改
                label: "数据看板",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/数据看板.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/数据看板-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/datacenter/board/board-folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/datacenter/board"// 用来根据路径选中当前模块
            },
            {
                parent: "数据中心",// 对应的一级名称，所有项目一致，无需修改
                name: "数据池",// 所有项目统一名称,无需修改
                label: "四诊数据",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/数据池.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/数据池-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/datacenter/pool/pool-list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/datacenter/pool",// 用来根据路径选中当前模块
                ifShowToolBtn: true
            },
            {
                parent: "数据中心",// 对应的一级名称，所有项目一致，无需修改
                name: "HIS数据",// 所有项目统一名称,无需修改
                label: "HIS数据",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/数据看板.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/数据看板-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/datacenter/his/his-list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/datacenter/his"// 用来根据路径选中当前模块
            },
            {
                parent: "数据中心",// 对应的一级名称，所有项目一致，无需修改
                name: "高级筛选",// 所有项目统一名称,无需修改
                label: "数据导出",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/高级筛选.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/高级筛选-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/datacenter/filter/filter-list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/datacenter/filter"// 用来根据路径选中当前模块
            },
        ]
    },
    专病数据库: {
        name: "专病数据库",// 一级目录名称，所有项目一致，无需修改
        label: "专病数据库",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        child: [
            {
                parent: "专病数据库",// 对应的一级名称，所有项目一致，无需修改
                name: "数据采集",// 所有项目统一名称,无需修改
                label: "数据采集",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/数据采集.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/数据采集-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/storehouse/collect/folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/storehouse/collect"// 用来根据路径选中当前模块
            },
            {
                parent: "专病数据库",// 对应的一级名称，所有项目一致，无需修改
                name: "病例库",// 所有项目统一名称,无需修改
                label: "病例库",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/病例库.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/病例库-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/storehouse/case/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/storehouse/case"// 用来根据路径选中当前模块
            },
            {
                parent: "专病数据库",// 对应的一级名称，所有项目一致，无需修改
                name: "质量控制",// 所有项目统一名称,无需修改
                label: "质量控制",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/质量控制.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/质量控制-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/storehouse/control/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/storehouse/control"// 用来根据路径选中当前模块
            },
            {
                parent: "专病数据库",// 对应的一级名称，所有项目一致，无需修改
                name: "监查痕迹",// 所有项目统一名称,无需修改
                label: "监查痕迹",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/监查痕迹.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/监查痕迹-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/storehouse/mark/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/storehouse/mark"// 用来根据路径选中当前模块
            },
            {
                parent: "专病数据库",// 对应的一级名称，所有项目一致，无需修改
                name: "项目报告",// 所有项目统一名称,无需修改
                label: "项目报告",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/项目报告.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/项目报告-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/storehouse/report/folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/storehouse/report"// 用来根据路径选中当前模块
            },
        ]
    },
    数据研究: {
        name: "数据研究",// 一级目录名称，所有项目一致，无需修改
        label: "数据标注",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        child: [
            {
                parent: "数据研究",// 对应的一级名称，所有项目一致，无需修改
                name: "舌图标注",// 所有项目统一名称,无需修改
                label: "舌图标注",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/智能看舌.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/智能看舌-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/research/tongue/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/research/tongue"// 用来根据路径选中当前模块
            },
            {
                parent: "数据研究",// 对应的一级名称，所有项目一致，无需修改
                name: "目图标注",// 所有项目统一名称,无需修改
                label: "目图标注",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/目图标注.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/目图标注-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/research/eye/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/research/eye"// 用来根据路径选中当前模块
            },
            {
                parent: "数据研究",// 对应的一级名称，所有项目一致，无需修改
                name: "手掌标注",// 所有项目统一名称,无需修改
                label: "手掌标注",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/手掌标注.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/手掌标注-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/research/hand/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/research/hand"// 用来根据路径选中当前模块
            },
            {
                parent: "数据研究",// 对应的一级名称，所有项目一致，无需修改
                name: "脉图标注",// 所有项目统一名称,无需修改
                label: "脉图标注",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/脉图标注.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/脉图标注-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/research/pulse/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/research/pulse"// 用来根据路径选中当前模块
            },
        ]
    },
    分析挖掘: {
        name: "分析挖掘",// 一级目录名称，所有项目一致，无需修改
        label: "统计分析",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        child: [
            // {
            //     parent: "分析挖掘",// 对应的一级名称，所有项目一致，无需修改
            //     name: "舌图分析",// 所有项目统一名称,无需修改
            //     label: "舌图分析",// 二级目录显示文本，每个项目可自行配置
            //     isShow: true,// 控制当前模块是否显示，每个项目可自行配置
            //     img: "/nav/舌图分析.png",// 当前模块图标，每个项目可自行配置
            //     imgActive: "/nav/舌图分析-选中.png",// 当前模块选中图标，每个项目可自行配置
            //     path: "/main/analysis/shetu/shetu-folder",// 点击跳转的路径，每个项目可自行配置
            //     activePath: "/main/analysis/shetu"// 用来根据路径选中当前模块
            // },
            // {
            //     parent: "分析挖掘",// 对应的一级名称，所有项目一致，无需修改
            //     name: "经验挖掘",// 所有项目统一名称,无需修改
            //     label: "经验挖掘",// 二级目录显示文本，每个项目可自行配置
            //     isShow: true,// 控制当前模块是否显示，每个项目可自行配置
            //     img: "/nav/经验挖掘.png",// 当前模块图标，每个项目可自行配置
            //     imgActive: "/nav/经验挖掘-选中.png",// 当前模块选中图标，每个项目可自行配置
            //     path: "/main/analysis/wajue/wajue-folder",// 点击跳转的路径，每个项目可自行配置
            //     activePath: "/main/analysis/wajue"// 用来根据路径选中当前模块
            // },
            {
                parent: "分析挖掘",// 对应的一级名称，所有项目一致，无需修改
                name: "统计分析",// 所有项目统一名称,无需修改
                label: "统计分析",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/统计分析.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/统计分析-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/analysis/tongji/tongji-folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/analysis/tongji"// 用来根据路径选中当前模块
            },
        ]
    },
    课题管理: {
        name: "课题管理",// 一级目录名称，所有项目一致，无需修改
        label: "课题管理",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        child: [
            {
                parent: "课题管理",// 对应的一级名称，所有项目一致，无需修改
                name: "采集说明",// 所有项目统一名称,无需修改
                label: "采集说明",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/账户管理.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/账户管理-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/topic/caijishuoming/folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/topic/caijishuoming/folder",// 用来根据路径选中当前模块
            }
        ]
    },
    用户权限管理: {
        name: "用户权限管理",// 一级目录名称，所有项目一致，无需修改
        label: "系统管理",// 一级目录显示文本，每个项目可自行配置
        isShow: true,
        child: [
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "账户管理",// 所有项目统一名称,无需修改
                label: "账户管理",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/账户管理.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/账户管理-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/zhanghu/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/zhanghu",// 用来根据路径选中当前模块
            },
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "角色管理",// 所有项目统一名称,无需修改
                label: "角色管理",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/角色管理.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/角色管理-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/juese/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/juese",// 用来根据路径选中当前模块
            },
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "组织架构",// 所有项目统一名称,无需修改
                label: "组织架构",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/组织架构.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/组织架构-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/zuzhi/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/zuzhi",// 用来根据路径选中当前模块
            },
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "数据权限管理",// 所有项目统一名称,无需修改
                label: "数据权限管理",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/数据权限管理.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/数据权限管理-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/quanxian/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/quanxian"// 用来根据路径选中当前模块
            },
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "邀请码管理",// 所有项目统一名称,无需修改
                label: "邀请码管理",// 二级目录显示文本，每个项目可自行配置
                isShow: false,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/邀请码管理.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/邀请码管理-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/yaoqing/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/yaoqing"// 用来根据路径选中当前模块
            },
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "资料管理",// 所有项目统一名称,无需修改
                label: "资料管理",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/邀请码管理.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/邀请码管理-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/ziliao/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/ziliao"// 用来根据路径选中当前模块
            },
            {
                parent: "用户权限管理",// 对应的一级名称，所有项目一致，无需修改
                name: "方案管理",// 所有项目统一名称,无需修改
                label: "方案管理",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/项目报告.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/项目报告-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/manage/fangan/list",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/manage/fangan"// 用来根据路径选中当前模块
            },
        ]
    },
    基本信息: {
        name: "基本信息",
        label: "基本信息",
        isShow: true,
        child: [
            {
                parent: "基本信息",// 对应的一级名称，所有项目一致，无需修改
                name: "账户安全",// 所有项目统一名称,无需修改
                label: "账户安全",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/账户安全.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/账户安全-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/account/safe/safe-folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/account/safe"// 用来根据路径选中当前模块
            },
            {
                parent: "基本信息",// 对应的一级名称，所有项目一致，无需修改
                name: "个人资料",// 所有项目统一名称,无需修改
                label: "个人资料",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/个人资料.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/个人资料-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/account/personal/personal-folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/account/personal"// 用来根据路径选中当前模块
            },
            {
                parent: "基本信息",// 对应的一级名称，所有项目一致，无需修改
                name: "帮助中心",// 所有项目统一名称,无需修改
                label: "帮助中心",// 二级目录显示文本，每个项目可自行配置
                isShow: true,// 控制当前模块是否显示，每个项目可自行配置
                img: "/nav/帮助中心.png",// 当前模块图标，每个项目可自行配置
                imgActive: "/nav/帮助中心-选中.png",// 当前模块选中图标，每个项目可自行配置
                path: "/main/account/help/help-folder",// 点击跳转的路径，每个项目可自行配置
                activePath: "/main/account/help"// 用来根据路径选中当前模块
            },
        ]
    }
};
/**
 * @description 根据环境配置api
 */
const option = (): any => {
    // const env = process.env.NODE_ENV;
    let dsObj = {};
    if (process.env.NODE_ENV === "development") {
        window.API_ROOT = "https://saas-test.i-tcm.cn/";
        // window.API_ROOT = "https://saas.i-tcm.cn/";
        // window.API_ROOT = "http://10.11.32.88:9010/";
        window.qrsrc = "/common/saas-test.jpg";
        document.title = "工作站";
        dsObj = {
            项目名称: "",
            登录页名称1: "",
            登录页名称2: "",
            loginBg: "url('/access/bg.png')",//登录页背景
            关键词: "专病",
            小程序名称: "岐黄数据AI工作站",
            // logo: '/common/saas.png',
            logo: '',
        };
    } else if (process.env.VUE_APP_ENV === "test") {
        window.API_ROOT = "https://saas-test.i-tcm.cn/";
        window.qrsrc = "/common/saas-test.jpg";
        document.title = "工作站";
        // document.title = "岐黄数据AI工作站";
        dsObj = {
            项目名称: "",
            登录页名称1: "",
            登录页名称2: "",
            loginBg: "url('/access/bg.png')",//登录页背景
            关键词: "专病",
            小程序名称: "中医远程数据智能研究系统",
            // logo: '/common/saas.png',
            logo: '',
        };
        // dsObj = {
        //     项目名称: "中医远程数据智能研究系统",
        //     登录页名称1: "中医远程数据智能研究系统",
        //     登录页名称2: "",
        //     关键词: "专病",
        //     小程序名称: "中医远程数据智能研究系统",
        //     logo: '/common/saas.png',
        // };
    } else if (process.env.VUE_APP_ENV === "production") {
        window.API_ROOT = "https://saas.i-tcm.cn/";
        window.qrsrc = "/common/saas-production.jpg";
        document.title = "岐黄数据AI工作站";
        dsObj = {
            项目名称: "岐黄数据AI工作站",
            登录页名称1: "岐黄数据AI工作站",
            登录页名称2: "",
            关键词: "专病",
            小程序名称: "岐黄数据AI工作站",
            logo: '/common/saas.png',
        };
    }
    return dsObj
};
export { option, partConfiguration };